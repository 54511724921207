article.index {
  background-color: var(--color-true-white) !important;
  text-align: center;
  display: block !important;

  .introSection {
    background-color: var(--color-bg);
    display: flex;
    justify-content: center;
    width: 100%;

    .introContent {
      max-width: var(--article-max-width);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-block: 2rem;
      padding-inline: 1rem;

      .topTitle {
        font-family: var(--landingPage-font-family);
        font-weight: var(--landingPage-font-weight);
      }

      .introLogo {
        max-width: 10rem !important;
        img {
          width: 100%;
          height: auto;
        }
      }

      .introImage {
        max-width: 35rem !important;
        padding: 1rem;
        img {
          width: 100%;
          height: auto;
        }
      }

      .mainTitle {
        margin-block: 1rem;
      }

      .secondaryTitle {
        font-weight: normal;
        margin-bottom: 2rem;
      }

      button {
        margin-bottom: 1rem;
      }
    }
  }

  .infoSection {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    width: 100%;

    .infoContent {
      max-width: var(--article-max-width);
      width: 100%;
      padding-block: 2rem;
      padding-inline: 1rem;
    }
  }
}
